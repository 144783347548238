import styled from 'styled-components';
import sx from '../../utilities/sx';
var Card = styled.div.attrs(function (props) {
  return {
    variant: props.variant || 'default'
  };
}).withConfig({
  displayName: "Card",
  componentId: "sc-c7fs5j-0"
})(["box-shadow:", ";border-radius:", ";color:", ";background-color:", ";margin-bottom:", ";& > *{border-color:", ";}", ""], function (props) {
  return "inset 0 0 0 ".concat(props.theme.borderWidths[1], " ").concat(props.theme.colors.card[props.variant].border);
}, function (props) {
  return props.theme.radii[1];
}, function (props) {
  return props.theme.colors.card[props.variant].text;
}, function (props) {
  return props.theme.colors.card[props.variant].bg;
}, function (props) {
  return props.theme.space[5];
}, function (props) {
  return props.theme.colors.card[props.variant].border;
}, sx);
Card.displayName = 'Card';
export default Card;