import { em, rem } from 'polished';
import colors from './baseColors';
var fontSizes = [rem(11), rem(13), rem(16), rem(20), rem(25), rem(31), rem(39)];
fontSizes.h1 = fontSizes[6];
fontSizes.h2 = fontSizes[5];
fontSizes.h3 = fontSizes[4];
fontSizes.h4 = fontSizes[3];
fontSizes.body = fontSizes[2];
var radii = ['0', '3px'];
radii.avatar = '50%';
radii.round = '50%';
var space = ['0', '4px', '8px', '16px', '24px', '32px', '40px', '48px', '64px', '80px', '96px', '112px', '128px'];
space.tiny = space[1];
space.small = space[2];
space.medium = space[3];
space.large = space[4];
space.xlarge = space[5];
var theme = {
  animation: {
    easeOutCubic: 'cubic-bezier(0.33, 1, 0.68, 1)'
  },
  borders: {
    none: "0px none transparent",
    "default": "1px solid ".concat(colors.border),
    documentField: "2px solid ".concat(colors.textLoud),
    timeline: "3px solid ".concat(colors.border),
    timelineViewedStatus: "1px dashed ".concat(colors.border)
  },
  borderWidths: ['0', '1px', '2px', '4px', '8px'],
  breakpoints: [em(544), em(768), em(1012), em(1280)],
  colors: colors,
  fonts: {
    normal: 'Roboto, sans-serif',
    mono: 'Inconsolata, monospace'
  },
  fontSizes: fontSizes,
  fontWeights: {
    normal: 400,
    bold: 700
  },
  lineHeights: {
    condensedUltra: 1,
    condensed: 1.25,
    "default": 1.5
  },
  radii: radii,
  shadows: {
    dropdown: '0 5px 10px -5px rgba(0,0,0,.3)',
    stack: '0 5px 10px -5px rgba(0,0,0,.3)'
  },
  sizes: {
    tiny: '300px',
    small: '544px',
    medium: '768px',
    large: '1012px',
    xlarge: '1280px'
  },
  space: space
};
export default theme;