import * as FullStory from '@fullstory/browser';
import { FIREBASE_PROJECT_ID, FULLSTORY_ORG_ID } from '@paperstac/env';
import * as React from 'react';
import { trpc } from '../services/trpc';
import useCurrentIdentity from './useCurrentIdentity';

const ConfigureFullStory = () => {
  const [identity] = useCurrentIdentity();
  const [linkAttempted, setLinkAttempted] = React.useState(false);
  const { mutateAsync } = trpc.linkFullStoryUserId.useMutation();

  React.useEffect(() => {
    FullStory.init({
      orgId: FULLSTORY_ORG_ID,
      devMode: !['paperstac-dev', 'paperstac-prod'].includes(FIREBASE_PROJECT_ID),
    });
  }, []);

  React.useEffect(() => {
    if (identity?.id) {
      FullStory.identify(identity?.id);
      FullStory.setUserVars({
        displayName: identity.displayName,
        email: identity.email,
        properties: { domain: 'noteclosings.com' },
      });
    } else {
      FullStory.anonymize();
    }
  }, [identity?.displayName, identity?.email, identity?.id]);

  React.useEffect(() => {
    if (identity?.id && !identity?.fullStoryUserId && !linkAttempted) {
      setLinkAttempted(true);
      mutateAsync().catch((e) => console.log(e));
    }
  }, [identity?.fullStoryUserId, identity?.id, linkAttempted, mutateAsync]);

  return null;
};

ConfigureFullStory.displayName = 'ConfigureFullStory';

export default React.memo(ConfigureFullStory);
