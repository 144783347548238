import { LOGOUT } from '@paperstac/routes/lib/noteclosingsRoutes';
import { useRouter } from 'next/router';
import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../services/firebase';
import useGlobalSettings from './useGlobalSettings';

type Props = {
  children: any;
};

const SessionTimeout: React.VFC<Props> = ({ children }: Props) => {
  const router = useRouter();
  const [authUser] = useAuthState(auth);
  const [globalSettings, globalSettingsLoading] = useGlobalSettings();
  const sessionDuration = globalSettings ? globalSettings?.sessionDuration || 0 : 0;

  React.useEffect(() => {
    if (globalSettingsLoading || !authUser) return;

    authUser.getIdTokenResult().then((idTokenResult) => {
      if (idTokenResult?.claims?.auth_time && sessionDuration) {
        const authTime = +idTokenResult.claims.auth_time * 1000;
        const currentSessionDuration = Date.now() - authTime;
        const sessionExpiration = 1000 * 60 * 60 * 24 * sessionDuration;
        if (currentSessionDuration > sessionExpiration) {
          console.log('Max session duration has been exceeded. Logging out...');
          router.push(LOGOUT);
        }
      }
    });
  }, [authUser, globalSettingsLoading, router, sessionDuration]);

  return children;
};

SessionTimeout.displayName = 'SessionTimeout';

export default SessionTimeout;
