import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../services/firebase';

const LoadFirebaseAuthState = ({ children }: { children: JSX.Element }) => {
  const [authUser, isLoadingAuthState] = useAuthState(auth);

  return isLoadingAuthState ? null : children;
};

LoadFirebaseAuthState.displayName = 'LoadFirebaseAuthState';

export default LoadFirebaseAuthState;
