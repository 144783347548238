import styled from 'styled-components';
import sx from '../../utilities/sx';
var UnstyledButton = styled.button.attrs(function (props) {
  return {
    type: props.type || 'button'
  };
}).withConfig({
  displayName: "UnstyledButton",
  componentId: "sc-1bszcs1-0"
})(["border:none;background:transparent;padding:0;outline:none;color:inherit;box-shadow:none;text-align:left;font-size:inherit;font-weight:inherit;border-radius:0;display:inline-block;cursor:default;", ""], sx);
export default UnstyledButton;