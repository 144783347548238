import styled from 'styled-components';
import sx from '../../utilities/sx';
var Box = styled.div.withConfig({
  displayName: "Box",
  componentId: "sc-8dc9vy-0"
})(["", ";", ";", ";border-radius:", ";", ";", ";", ""], function (props) {
  return !!props.variant && "color: ".concat(props.theme.colors.box[props.variant].text);
}, function (props) {
  return !!props.variant && "background-color: ".concat(props.theme.colors.box[props.variant].bg);
}, function (props) {
  return !!props.variant && "border: ".concat(props.theme.borderWidths[1], " solid ").concat(props.theme.colors.box[props.variant].border);
}, function (props) {
  return props.theme.radii[1];
}, function (props) {
  return !!props.layoutContainer && "\n      padding-left: ".concat(props.theme.space[4], ";\n      padding-right: ").concat(props.theme.space[4], ";\n  ");
}, function (props) {
  return !!props.layoutContainerBreakout && "\n      margin-left: -".concat(props.theme.space[4], ";\n      margin-right: -").concat(props.theme.space[4], ";\n  ");
}, sx);
export default Box;