var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export * from './actionTokenRoutes';
export * from './authRoutes';
export var ACTION = '/action';
export var CLOSING = '/closings/[...params]';
export var CLOSINGS = '/closings';
export var CREATE_ACCOUNT = '/create-account';
export var HOME = '/';
export var CREATE_CLOSING = '/closings/new';
export var TERMS = '/terms';
export var MANAGE_IDENTITY = '/manage-identity';
export var getClosingPathFromConfig = function (_a) {
    var action = _a.action, assetId = _a.assetId, closingId = _a.closingId, shipmentId = _a.shipmentId, tab = _a.tab, wizard = _a.wizard;
    var segments = [CLOSINGS, closingId];
    if (tab)
        segments.push('tab', tab);
    if (action)
        segments.push('action', action);
    if (assetId)
        segments.push('asset-id', assetId);
    if (shipmentId)
        segments.push('shipment-id', shipmentId);
    if (wizard)
        segments.push('wizard', wizard);
    return segments.join('/');
};
export var getClosingNextQueryFromConfig = function (_a) {
    var action = _a.action, assetId = _a.assetId, closingId = _a.closingId, shipmentId = _a.shipmentId, tab = _a.tab, wizard = _a.wizard;
    var params = [closingId];
    if (tab)
        params.push('tab', tab);
    if (action)
        params.push('action', action);
    if (assetId)
        params.push('asset-id', assetId);
    if (shipmentId)
        params.push('shipment-id', shipmentId);
    if (wizard)
        params.push('wizard', wizard);
    return { params: params };
};
export var getClosingConfigFromNextQuery = function (query) {
    var params = Array.isArray(query.params) ? __spreadArray([], query.params, true) : [query.params];
    var closingId = params.shift();
    if (!closingId)
        throw new Error('The closingId was missing from the config');
    var config = { closingId: closingId };
    while (params.length > 1) {
        var property = params.shift();
        if (property === 'tab') {
            var value = params.shift();
            if (value === 'assets' ||
                value === 'files' ||
                value === 'orders' ||
                value === 'participants' ||
                value === 'shipping' ||
                value === 'terms' ||
                value === 'timeline' ||
                value === 'todos')
                config.tab = value;
        }
        if (property === 'action') {
            config.action = params.shift();
        }
        if (property === 'asset-id') {
            config.assetId = params.shift();
        }
        if (property === 'shipment-id') {
            config.shipmentId = params.shift();
        }
        if (property === 'wizard') {
            var value = params.shift();
            if (value === 'allonge-details' ||
                value === 'allonge-preparer' ||
                value === 'allonge-signers' ||
                value === 'assignment-details' ||
                value === 'assignment-preparer' ||
                value === 'assignment-return-address' ||
                value === 'assignment-signers' ||
                value === 'buyer' ||
                value === 'closing-date' ||
                value === 'collateral-shipping-address' ||
                value === 'deal' ||
                value === 'deed-preparer' ||
                value === 'fees' ||
                value === 'funding-escrow' ||
                value === 'note-type' ||
                value === 'online-execution' ||
                value === 'psa-buyer-signers' ||
                value === 'psa-preparer' ||
                value === 'psa-seller-signers' ||
                value === 'seller' ||
                value === 'seller-bank-wire-instructions' ||
                value === 'side-letter-details' ||
                value === 'side-letter-required' ||
                value === 'start')
                config.wizard = value;
        }
    }
    return config;
};
