import { CampaignParams } from '@paperstac/types/src/Campaign';
import Cookies from 'js-cookie';
import * as React from 'react';
import useLocalStorageState from 'use-local-storage-state';

const getCampaignFromCookie = (name: string): CampaignParams | undefined => {
  const campaignJson = Cookies.get(name);
  if (!campaignJson) return undefined;
  const campaign = JSON.parse(campaignJson);
  if (!campaign?.campaign) return undefined;
  return {
    campaign: campaign.campaign || '',
    source: campaign.source || '',
    medium: campaign.medium || '',
    term: campaign.term || '',
    content: campaign.content || '',
  };
};

const getCampaignFromUrl = (): CampaignParams | undefined => {
  if (typeof window === 'undefined') return undefined;
  const params = new URLSearchParams(window.location.search);
  if (!params.get('utm_campaign')) return undefined;
  return {
    campaign: params.get('utm_campaign') || '',
    source: params.get('utm_source') || '',
    medium: params.get('utm_medium') || '',
    term: params.get('utm_term') || '',
    content: params.get('utm_content') || '',
  };
};

const getCampaignFromReferrer = (): CampaignParams | undefined => {
  if (typeof window === 'undefined') return undefined;
  if (!window.document.referrer) return undefined;
  const parser = window.document.createElement('a');
  parser.href = window.document.referrer;
  return {
    campaign: parser.pathname.charAt(0) !== '/' ? `/${parser.pathname}` : parser.pathname,
    source: parser.hostname,
    medium: 'referral',
    term: '',
    content: '',
  };
};

type ContextValue = {
  campaign: CampaignParams;
  setCampaign: (campaign: CampaignParams) => void;
};

const Context = React.createContext<ContextValue>({
  campaign: {} as CampaignParams,
  setCampaign: () => {},
});

type CampaignProviderProps = {
  campaignKey: string;
  children: React.ReactNode;
  defaultCampaign: CampaignParams;
};

const CampaignProvider = React.memo(({ campaignKey, children, defaultCampaign }: CampaignProviderProps) => {
  const currentCampaign: CampaignParams =
    getCampaignFromCookie(campaignKey) || getCampaignFromUrl() || getCampaignFromReferrer() || defaultCampaign;
  const [campaign, setCampaign] = useLocalStorageState<CampaignParams>(campaignKey, { defaultValue: currentCampaign });

  React.useEffect(() => {
    Cookies.set(campaignKey, JSON.stringify(campaign), { expires: 7 });
  }, [campaign, campaignKey]);

  const providerValue = React.useMemo(
    () => ({
      campaign,
      setCampaign,
    }),
    [campaign, setCampaign]
  );

  return <Context.Provider value={providerValue}>{children}</Context.Provider>;
});

CampaignProvider.displayName = 'CampaignProvider';

export default CampaignProvider;

export function useCampaign(): ContextValue {
  return React.useContext(Context);
}
