import React from 'react';
var useBodyLockScroll = function useBodyLockScroll(skipBodyLock) {
  React.useLayoutEffect(function () {
    if (skipBodyLock) return;
    var originalStyle = window.getComputedStyle(document.body).overflow;
    document.body.style.overflow = 'hidden';
    return function () {
      document.body.style.overflow = originalStyle;
    };
  }, [skipBodyLock]);
};
export default useBodyLockScroll;