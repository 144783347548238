import { adjustHue, darken, normalize } from 'polished';
import { createGlobalStyle } from 'styled-components';
var GlobalStyles = createGlobalStyle(["", " *,*::before,*::after{box-sizing:border-box;}html{height:100%;}body{height:100%;font-family:", ";font-size:", ";line-height:", ";color:", ";background-color:", ";}a,a:visited{color:", ";text-decoration:underline;&:hover,&:focus{color:", ";text-decoration:underline;}}h1,h2,h3,h4{color:", ";}h1{font-size:", ";}h2{font-size:", ";}h3{font-size:", ";}h4{font-size:", ";}table{width:100%;border-spacing:0;border-collapse:collapse;}@keyframes button-busy{from{transform:rotate(0deg);}to{transform:rotate(360deg);}}"], normalize(), function (props) {
  return props.theme.fonts.normal;
}, function (props) {
  return props.theme.fontSizes.body;
}, function (props) {
  return props.theme.lineHeights["default"];
}, function (props) {
  return props.theme.colors.text;
}, function (props) {
  return props.theme.colors.background;
}, function (props) {
  return props.theme.colors.link;
}, function (props) {
  return darken(0.1, adjustHue(15, props.theme.colors.link));
}, function (props) {
  return props.theme.colors.textLoud;
}, function (props) {
  return props.theme.fontSizes.h1;
}, function (props) {
  return props.theme.fontSizes.h2;
}, function (props) {
  return props.theme.fontSizes.h3;
}, function (props) {
  return props.theme.fontSizes.h4;
});
GlobalStyles.displayName = 'GlobalStyles';
export default GlobalStyles;