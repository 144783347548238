import { CREATE_ACCOUNT } from '@paperstac/routes/lib/noteclosingsRoutes';
import { useRouter } from 'next/router';
import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../services/firebase';
import { useCurrentAccounts } from './CurrentAccountsProvider';

type Props = {
  children: any;
};

const RedirectToCreateAccount: React.VFC<Props> = ({ children }: Props) => {
  const [authUser] = useAuthState(auth);
  const currentAccounts = useCurrentAccounts();
  const router = useRouter();

  React.useEffect(() => {
    if (router.route !== CREATE_ACCOUNT && !!authUser && Array.isArray(currentAccounts) && !currentAccounts.length) {
      router.push(CREATE_ACCOUNT);
    }
  }, [currentAccounts, router, authUser]);

  return children;
};

export default RedirectToCreateAccount;
