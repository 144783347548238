import { darken, desaturate, lighten, mix } from 'polished';
var background = '#e8eef0';
var border = darken(0.1, background);
var error = '#d9534f';
var info = '#0d6efd';
var link = '#0d6efd';
var primary = '#0d6efd';
var secondary = '#6c757d';
var success = '#5cb85c';
var surface = '#fff';
var surfaceMuted = mix(0.5, background, surface);
var text = '#35393a';
var textLoud = '#000';
var textMuted = '#747e81';
var warning = '#d9534f';
var errorText = mix(0.5, error, text);
var errorSurface = mix(0.15, error, surface);
var errorBorder = desaturate(0.2, darken(0.1, errorSurface));
var highlight = '#fbdd4b';
var highlightText = mix(0.4, highlight, text);
var highlightSurface = mix(0.08, highlight, surface);
var highlightBorder = desaturate(0.5, darken(0.1, highlightSurface));
var infoText = mix(0.5, info, text);
var infoSurface = mix(0.12, info, surface);
var infoBorder = desaturate(0.2, darken(0.1, infoSurface));
var primaryText = mix(0.5, info, text);
var primarySurface = mix(0.12, info, surface);
var primaryBorder = desaturate(0.2, darken(0.1, infoSurface));
var successText = mix(0.4, success, text);
var successSurface = mix(0.15, success, surface);
var successBorder = desaturate(0.1, darken(0.1, successSurface));
var colors = {
  background: background,
  border: border,
  error: error,
  errorBorder: errorBorder,
  errorSurface: errorSurface,
  errorText: errorText,
  highlight: highlight,
  highlightBorder: highlightBorder,
  highlightSurface: highlightSurface,
  highlightText: highlightText,
  info: info,
  infoBorder: infoBorder,
  infoSurface: infoSurface,
  infoText: infoText,
  link: link,
  primary: primary,
  primaryBorder: primaryBorder,
  primarySurface: primarySurface,
  primaryText: primaryText,
  secondary: secondary,
  success: success,
  successBorder: successBorder,
  successSurface: successSurface,
  successText: successText,
  surface: surface,
  surfaceInverted: textLoud,
  surfaceMuted: surfaceMuted,
  text: text,
  textInverted: surface,
  textLoud: textLoud,
  textMuted: textMuted,
  warning: warning,
  badge: {
    "default": {
      bg: surfaceMuted,
      border: border,
      text: text
    },
    error: {
      bg: errorSurface,
      border: errorBorder,
      text: errorText
    },
    errorLoud: {
      bg: error,
      border: error,
      text: surface
    },
    primary: {
      bg: primarySurface,
      border: primaryBorder,
      text: primaryText
    },
    primaryLoud: {
      bg: primary,
      border: primary,
      text: surface
    },
    success: {
      bg: successSurface,
      border: successBorder,
      text: successText
    },
    successLoud: {
      bg: success,
      border: success,
      text: surface
    }
  },
  box: {
    surface: {
      bg: surface,
      border: border,
      text: text
    }
  },
  button: {
    "default": {
      bg: secondary,
      bgDisabled: desaturate(0.3, mix(0.4, surface, secondary)),
      bgHover: darken(0.1, secondary),
      text: surface,
      textDisabled: mix(0.2, secondary, surface),
      textHover: surface
    },
    primary: {
      bg: primary,
      bgDisabled: desaturate(0.3, mix(0.4, surface, primary)),
      bgHover: darken(0.1, primary),
      text: surface,
      textDisabled: mix(0.2, primary, surface),
      textHover: surface
    },
    text: {
      bg: 'transparent',
      bgDisabled: 'transparent',
      bgHover: '#eff9ff',
      text: text,
      textDisabled: lighten(0.2, textMuted),
      textHover: primary
    }
  },
  card: {
    "default": {
      bg: surface,
      border: border,
      text: text
    },
    error: {
      bg: errorSurface,
      border: errorBorder,
      text: errorText
    },
    highlight: {
      bg: highlightSurface,
      border: highlightBorder,
      text: highlightText
    },
    info: {
      bg: infoSurface,
      border: infoBorder,
      text: infoText
    },
    primary: {
      bg: primarySurface,
      border: primaryBorder,
      text: primaryText
    },
    success: {
      bg: successSurface,
      border: successBorder,
      text: successText
    }
  },
  input: {
    active: {
      bg: surface,
      border: primary,
      text: textLoud
    },
    aside: {
      bg: surfaceMuted,
      border: border,
      text: text
    },
    "default": {
      bg: surface,
      border: border,
      text: text
    },
    disabled: {
      bg: surfaceMuted,
      border: border,
      text: textMuted
    },
    error: {
      bg: surface,
      border: error,
      text: error
    }
  },
  menuItem: {
    "default": {
      bg: 'transparent',
      bgHover: '#eff9ff',
      text: text,
      textHover: primary
    }
  },
  stack: {
    "default": {
      bg: mix(0.5, surface, background),
      text: textMuted
    }
  }
};
export default colors;