import * as env from '@paperstac/env';
import { getAnalytics, isSupported as isAnalyticsSupported } from 'firebase/analytics';
import { getApp, getApps, initializeApp } from 'firebase/app';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { connectStorageEmulator, getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: env.FIREBASE_API_KEY,
  authDomain: env.FIREBASE_AUTH_DOMAIN,
  databaseURL: env.FIREBASE_DATABASE_URL,
  projectId: env.FIREBASE_PROJECT_ID,
  storageBucket: env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: env.FIREBASE_MESSAGING_SENDER_ID,
  appId: env.FIREBASE_APP_ID,
  measurementId: env.FIREBASE_MEASURE_ID,
};

const firebaseConfigured = !!getApps().length;
const useEmulator = process.env.NODE_ENV !== 'production';
const app = !firebaseConfigured ? initializeApp(firebaseConfig) : getApp();

export const analytics = async () => !!env.FIREBASE_MEASURE_ID && (await isAnalyticsSupported()) && getAnalytics(app);
export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const functions = getFunctions(app);
export const storage = getStorage(app);

if (!firebaseConfigured && useEmulator) {
  connectAuthEmulator(auth, 'http://localhost:9099');
  connectFirestoreEmulator(firestore, 'localhost', 8080);
  connectFunctionsEmulator(functions, 'localhost', 5001);
  connectStorageEmulator(storage, 'localhost', 9199);
} else {
  auth.tenantId = env.NOTE_CLOSINGS_TENANT_ID;
  analytics();
}
