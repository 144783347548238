import type { AppProps } from 'next/app';
import React from 'react';
import AppProviders from '../components/AppProviders';
import ConfigureFullStory from '../components/ConfigureFullStory';

declare global {
  interface Window {
    cloudinary: any;
  }
}

function MyApp({ Component, pageProps }: AppProps) {
  const ComponentTyped = Component as any;
  return (
    <AppProviders>
      <ConfigureFullStory />
      <ComponentTyped {...pageProps} />
    </AppProviders>
  );
}

export default MyApp;
