import { DocumentReference } from '@firebase/firestore';
import { IdentityFirestoreData } from '@paperstac/types/lib/Identity';
import { doc } from 'firebase/firestore';
import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { auth, firestore } from '../services/firebase';

export default function useCurrentIdentity() {
  const [authUser] = useAuthState(auth);
  const ref = authUser
    ? (doc(firestore, 'identities', authUser.uid) as DocumentReference<IdentityFirestoreData>)
    : null;
  return useDocumentData<IdentityFirestoreData>(ref);
}
