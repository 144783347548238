import { adjustHue, darken } from 'polished';
import styled from 'styled-components';
import sx from '../../utilities/sx';
var Link = styled.a.withConfig({
  displayName: "Link",
  componentId: "sc-845b1v-0"
})(["&&{cursor:pointer;color:", ";text-decoration:", ";text-transform:", ";&:hover,&:focus{color:", ";text-decoration:underline;}", " ", "}"], function (props) {
  return props.variant === 'muted' ? props.theme.colors.text : props.theme.colors.link;
}, function (props) {
  return props.variant === 'header' ? 'none' : 'underline';
}, function (props) {
  return props.variant === 'header' ? 'uppercase' : 'inherit';
}, function (props) {
  return darken(0.1, adjustHue(15, props.theme.colors.link));
}, function (props) {
  return props.variant === 'inherit' && "\n      color: inherit;\n      text-decoration: inherit;\n      \n      &:hover,\n      &:focus {\n        color: inherit;\n        text-decoration: inherit;\n      }\n    ";
}, sx);
export default Link;