import { DocumentReference } from '@firebase/firestore';
import { SettingsFirestoreData } from '@paperstac/types/lib/Settings';
import { doc } from 'firebase/firestore';
import React from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { firestore } from '../services/firebase';

export default function useGlobalSettings() {
  const ref = doc(firestore, 'settings', 'global') as DocumentReference<SettingsFirestoreData>;
  return useDocumentData<SettingsFirestoreData>(ref);
}
