import DialogProvider from '@paperstac/design/lib/organisms/DialogProvider/DialogProvider';
import DrawerProvider from '@paperstac/design/lib/organisms/DrawerProvider/DrawerProvider';
import ToastProvider from '@paperstac/design/lib/organisms/ToastProvider/ToastProvider';
import ThemeProvider from '@paperstac/design/lib/particles/ThemeProvider';
import noteclosings from '@paperstac/design/lib/particles/themes/noteclosings';
import React, { ReactNode } from 'react';
import CampaignProvider from './CampaignProvider';
import CurrentAccountsProvider from './CurrentAccountsProvider';
import LoadFirebaseAuthState from './LoadFirebaseAuthState';
import RedirectToCreateAccount from './RedirectToCreateAccount';
import SessionTimeout from './SessionTimeout';
import TrpcProvider from './TrpcProvider';

const DEFAULT_CAMPAIGN = {
  campaign: '',
  source: 'noteclosings.com',
  medium: 'direct',
  term: '',
  content: '',
};

function AppProviders({ children }: { children: ReactNode }) {
  return (
    <LoadFirebaseAuthState>
      <ThemeProvider theme={noteclosings}>
        <CampaignProvider campaignKey="nc-campaign" defaultCampaign={DEFAULT_CAMPAIGN}>
          <DrawerProvider>
            <DialogProvider>
              <ToastProvider>
                <TrpcProvider>
                  <CurrentAccountsProvider>
                    <RedirectToCreateAccount>
                      <SessionTimeout>{children}</SessionTimeout>
                    </RedirectToCreateAccount>
                  </CurrentAccountsProvider>
                </TrpcProvider>
              </ToastProvider>
            </DialogProvider>
          </DrawerProvider>
        </CampaignProvider>
      </ThemeProvider>
    </LoadFirebaseAuthState>
  );
}

AppProviders.displayName = 'AppProviders';

export default AppProviders;
